import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { Segment, Form, Button, Dimmer, Loader, Message, Dropdown, Grid, Image, Label, Icon, Input, Header, Popup, List, Checkbox } from 'semantic-ui-react'
import FilePicker from '../../../components/S3FileUpload/FilePicker'
import { Carousel } from 'react-responsive-carousel'
import { Promise } from 'bluebird';
import DatePicker from 'react-datepicker'
import { listDefaultSignageImages, listSignageImagesForContentTypeFromS3, uploadSignageFile } from '../../../services/ImageUpload'
import { addSignage, deleteSigange, fetchPaginatedActiveSignage, fetchSignageContent, sendSignageProactiveSuggestion, updateSignageContent } from '../../../services/Signage';
import { fetchRoomGroups } from '../../../services/RoomGroups'
import { fetchResidentRooms, fetchOneFacility } from '../../../services/Facilities'

import styles from "./Background.module.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./style.less"
import { SignageContent, SignageType } from '../../../types/signage.d';
import { Tab } from 'semantic-ui-react'
import Content from '../../../components/Content'
import { UserProfile } from '../../../types'
import { AppState } from '../../../reducers'
import moment from 'moment-timezone'
import { toast } from 'react-toastify';
import { ContentType } from "../../../types/signage"
import PhotoGrid from '../../../components/PhotoGrid'
import LoadMore from '../../../components/LoadMore'
import { sortByKey } from '../../../util/sortData';
import { sendToast } from '../../../util';
import { Group } from '../../../types/Group'

interface SignageFilter {
    StartDate?: any;
    EndDate?: any;
    SearchQuery?: string;
    RoomIds?: any;
}

const defaultSignageImage = 'https://speak2-signage.s3.amazonaws.com/default-images/signage/default-bg-1.jpg';
const defaultSvcImage = "https://speak2-signage.s3.amazonaws.com/default-images/instance-signage/service-instance-default.jpg";
const Signage = () => {
    const [startDateTimeString, setStartDateTimeString] = useState<string>("");
    const [endDateTimeString, setEndDateTimeString] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false)
    const [imagesLoading, setImagesLoading] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [imageFile, setImageFile] = useState<File[]>([]);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [fileSizeExceeded, setFileSizeExceeded] = useState<string>("");
    const [onSuccess, setOnSuccess] = useState<boolean>(false);
    const [notifyingRoom, setNotifyingRoom] = useState<number>(1);
    const [totalRooms, setTotalRooms] = useState<number>(0)
    const [previewTime, setPreviewTime] = useState("")
    const [disableSave, setDisableSave] = useState<boolean>(true)
    const [errorTitle, setErrorTitle] = useState<boolean | { content: string, pointing: string }>(false)
    const [errorSubTitle, setErrorSubTitle] = useState<boolean | { content: string, pointing: string }>(false)
    const [signages, setSignages] = useState<SignageType[]>([])
    //by default a blank white image is used for preview
    const [previewUrl, setPreviewUrl] = useState<string>(defaultSignageImage);
    const [templateUrl, setTemplateUrl] = useState<string>(defaultSignageImage);
    const [selectedRooms, setSelectedRooms] = useState<string[]>(["all"])
    const [nextContinuationToken, setNextContinuationToken] = useState<string | null>(null)
    const [galleryImages, setGalleryImages] = useState<string[]>([])
    const [imageETags, setImageEtags] = useState<string[]>([])
    const profile = useSelector((state: AppState) => state && state.authReducer && state.authReducer.profile)
    const [formValues, setFormValues] = useState({
        title: "",
        subTitle: ""
    })
    const [previewImgSize, setPreviewImgSize] = useState({
        Height: 0,
        Width: 0
    })
    const previewImgRef = useRef<any>({
        current: {
            clientHeight: 0,
            clientWidth: 0
        }
    });
    const [imageUploadStatus, setImageUploadStatus] = useState({
        percentage: 0,
        isUploading: false,
        finished: false,
        error: "",
    })
    const [rooms, setRooms] = useState([
        { key: "all", text: "All apartments", value: "all" }
    ])
    const [signageContentObj, setSignageContentObj] = useState<SignageContent>({ Facility: "", _id: "" })
    const [facilityTimeZone, setFacilityTimeZone] = useState<string | undefined>("")
    const [pageNum, setPageNum] = useState(1);
    const [hasMoreTemplates, setHasMoreTemplates] = useState(true);
    const [activeTabIndex, setActiveTabIndex] = useState<string | number | undefined>(0);
    const [myImagesActiveTab, setMyImagesActiveTab] = useState<string | number | undefined>(undefined);
    const [activeContentTypeTab, setActiveContentTypeTab] = useState<ContentType | null>(null)
    const [activeGalleryTabIndex, setActiveGalleryTabIndex] = useState<string | number | undefined>(0);
    const [selectedSlide, setSelectedSlide] = useState< number >(0);
    const [dateRange, setDateRange] = useState<{ startDate: Date | null, endDate: Date | null }>({
        startDate: null,
        endDate: null,
    })
    const [showDateRange, setShowDateRange] = useState(false);
    const [isFetchingTemplates, setIsFetchingTemplates] = useState(false)
    const pageSize = 20;
    const imagePerPage = 30;
    const contentTypes: ContentType[] = ["myUploads", "ROTM", "SOTM", "menus", "activities", "communityMessages"];
    const defaultImageTypes: ContentType[] = ["backgrounds", "menu", "activities", "birthdays", "messages", "spotlight", "announcements", "seasons"];
    const [refreshSignages, setRefreshSignages] = useState(false)
    const [searchQuery, setSearchQuery] = useState<string>("")
    const [groups, setGroups] = useState<Group[]>([])
    const [selectedGroup, setSelectedGroup] = useState<string>("all")
    const [contentDisplayLocation, setContentDisplayLocation] = useState<string[]>([])
    const [signageDisplayLocation, setSignageDisplayLocation] = useState<string[]>(["Alexa"])

    const hasMounted = useRef(false);

    const tenMinutesFromNow = (date: Date) => {
        return new Date(date.setMinutes(date.getMinutes() + 10)).toISOString()
    }
    
    useEffect(() => {
        if (imageFile.length) {
            // create the preview without uploading
            const objectUrl = URL.createObjectURL(imageFile[0])
            setPreviewUrl(objectUrl)
            return () => URL.revokeObjectURL(objectUrl)
        }
    }, [imageFile])

    useEffect(() => {
        if(dateRange.startDate && dateRange.endDate){
            (async () => {
                setSignages([]); // clear previous data to avoid duplicates
                setPageNum(1);// clear page number 
                await refreshData();
            }
            )();
        }
    },[dateRange])

    useEffect(()=>{
        if(pageNum === 1){
            setSelectedSlide(signages.length - 1);
        } else {
            const viewedSlides = (pageNum-1) * pageSize;
            setSelectedSlide(signages.length - viewedSlides - 1);
        }
    },[signages])

    
    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true; // Mark as mounted
            return;
        }
        (async () => {
            setSignages([]); // clear previous data to avoid duplicates
            setPageNum(1);// clear page number 
            await refreshData();
        })();
    }, [selectedGroup]);


    useLayoutEffect(() => {
        // Set up a event listener - to update image render size to state, if it changes
        function updateSize() {
            if (previewImgRef && previewImgRef.current && previewImgRef.current.clientHeight && previewImgRef.current.clientWidth) {
                setPreviewImgSize({ Height: previewImgRef.current.clientHeight, Width: previewImgRef.current.clientWidth })
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        (async () => {
            setImageEtags([]);
            if (activeContentTypeTab && (myImagesActiveTab !== undefined)) {
                console.log("fiorst")
                setImagesLoading(true)
                const res = await listSignageImagesForContentTypeFromS3(activeContentTypeTab, imagePerPage, null);
                setGalleryImages(res.images);
                setNextContinuationToken(res.nextContinuationToken);
                if (res.etags) {
                    setImageEtags((prev) => [...prev, , ...res.etags]);
                }
                setImagesLoading(false);
            }
        })()
    }, [myImagesActiveTab])


    useEffect(() => {
        (async () => {
            if (activeContentTypeTab && (myImagesActiveTab === undefined)) {
                setImagesLoading(true);
                setNextContinuationToken(null);
                setImageEtags([]);
                const res = await listDefaultSignageImages(activeContentTypeTab, imagePerPage, null);
                if (res.images) setGalleryImages(res.images);
                setNextContinuationToken(res.nextContinuationToken);
                if (res.etags) {
                    setImageEtags((prev) => [...prev, , ...res.etags]);
                }
                setImagesLoading(false);
            }
        })();
    }, [activeContentTypeTab])

    const refreshData = async (signageId = "") => {
        try {
            setIsFetchingTemplates(true);
            // Refresh signage data
            const [signageData] = await Promise.all([fetchSignage()])            
            setHasMoreTemplates(signageData.length < pageSize ? false : true);
            //if signageId is there then dont set that signage in the list
            if (signageId) {
                setSignages((prev) => [...prev, ...signageData.filter((signage) => String(signage._id) !== String(signageId))]);
            } else {
                setSignages((prev) => [...prev, ...signageData]);
            }

            // Refresh rooms and room options once we allow editing of room groups

            // const [roomGroups, residentRooms] = await Promise.all([fetchRoomGroups(), fetchResidentRooms()])
            // const sortedRooms = formatAndSortRooms(residentRooms)
            // const sortedRoomGroups = formatAndSortRoomGroups(roomGroups)
            // const roomOptions = [{ key: 'all', text: 'All rooms', value: 'all' }]
            //     .concat(sortedRoomGroups)
            //     .concat(sortedRooms)
            // setRooms(roomOptions)
            setIsFetchingTemplates(false);
        } catch (e) {
            console.log("Error ", e)
        }
    }

    useEffect(() => {
        (async function () {
            await refreshData();
        })();
    }, [pageNum, refreshSignages])

    useEffect(() => {
        setLoading(true)
        const helper = async () => {
            try {
                const [roomGroups, residentRooms, signageContent] = await Promise.all([fetchRoomGroups(), fetchResidentRooms(), fetchSignageContentObj()])
                const sortedRooms = formatAndSortRooms(residentRooms)
                const sortedRoomGroups = formatAndSortRoomGroups(roomGroups)
                const roomOptions = [{ key: 'all', text: 'All apartments', value: 'all' }]
                    .concat(sortedRoomGroups)
                    .concat(sortedRooms)
                setRooms(roomOptions)
                setGroups(roomGroups)
                setContentDisplayLocation(signageContent.displayLocation || ["Alexa"])
                setSignageContentObj(signageContent)

                if (profile) {
                    const { FacilityTimeZone } = await fetchOneFacility(profile.Facility);
                    setFacilityTimeZone(FacilityTimeZone);
                }

                setLoading(false)
            } catch (e) {
                console.log('Error : ', e)
                setLoading(false)
            }
        }
        helper()
    }, [])

    useEffect(() => {
        // Disable/Enable save button
        if (!formValues.title.length) {
            setDisableSave(true);
        } else if (formValues.title.length > 25) {
            setDisableSave(true);
            setErrorTitle({ content: "Title should be less than 25 characters", pointing: "below" })
        } else {
            setErrorTitle(false)
        }
        if (!formValues.subTitle.length) {
            setDisableSave(true);
        } else if (formValues.subTitle.length > 60) {
            setDisableSave(true);
            setErrorSubTitle({ content: "subTitle should be less than 60 characters", pointing: "below" })
        } else {
            setErrorSubTitle(false)
        }
        if (!startDateTimeString) {
            setDisableSave(true);
        }
        if (!endDateTimeString) {
            setDisableSave(true);
        }
        if (!imageFile.length) {
            setDisableSave(true);
        }
        if (!selectedRooms.length) {
            setDisableSave(true);
        }
        if (
            formValues.title.length &&
            formValues.title.length < 26 &&
            formValues.subTitle.length &&
            formValues.subTitle.length < 61 &&
            startDateTimeString &&
            endDateTimeString &&
            (imageFile.length || templateUrl.length) &&
            selectedRooms.length
        ) {
            setDisableSave(false);
            setErrorSubTitle(false);
            setErrorTitle(false)
        }
    }, [formValues.title.length, formValues.subTitle.length, startDateTimeString, endDateTimeString, imageFile.length, selectedRooms.length])

    const fetchSignageContentObj = async () => {
        try {
            const signageContent = await fetchSignageContent(profile && profile.Facility);
            return signageContent;
        } catch (error) {
            sendToast("error", error instanceof Error && error.message ? error.message : "Failed to fetch signage content");
        }
    }
    const refreshGalleryImage = async () => {
        try {
            if (activeContentTypeTab && (myImagesActiveTab !== undefined)) {
                setImageEtags([]);
                setImagesLoading(true)
                const res = await listSignageImagesForContentTypeFromS3(activeContentTypeTab, imagePerPage, null);
                setGalleryImages(res.images);
                setNextContinuationToken(res.nextContinuationToken);
                if (res.etags) {
                    setImageEtags(res.etags);
                }
                setImagesLoading(false);
            } 
        } catch (error) {
            sendToast("error", "Oops! Something went wrong Please try again")
        } finally {
            setImagesLoading(false);
        }
    }

    const deleteTemplate = async (_id: string) => {
        try {
            setIsFetchingTemplates(true);
            setSignages([]); // to avoid duplications
            await deleteSigange(_id);
            await refreshData(_id);
            setIsFetchingTemplates(false);
        } catch (error) {
            console.error(error);
            // setErrorMsg("") // not using setErrorMsg here as it is not shown in the expected place which might lead to bad UX
            toast.error(error instanceof Error && error.message ? error.message : "Failed to delete signaeg", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    }
    const createCarouselItemImage = (signage: SignageType) => {
        const formatISODate = (date) => {
            const facilityTime = moment.tz(date, facilityTimeZone).format('MM/DD/YY h:mm A');
            return facilityTime;
        };

        const popupRoomNames =  signage.RoomDetails 
        ? signage.RoomDetails.map(el => el.Name).join(",\u00A0") 
        : "";
    
        const shortRoomNames = popupRoomNames.length > 25 ? popupRoomNames.substring(0, 25).concat("....") : popupRoomNames;

        const hasProactiveSuggestionEnded = signage.EndDate < new Date().toISOString()
        return (
            <div
                key={signage._id}
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}
            >
                <Image
                    src={signage.ImageUrl}
                    fluid
                    style={{
                        width: "300px",
                        height: "168.75px",
                        objectFit: "cover",
                        opacity: hasProactiveSuggestionEnded ? 0.5 : 1
                    }}
                />
                <div
                    style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        display: "flex",
                        gap: "10px"
                    }}
                >
                    <button
                        style={{
                            background: "rgb(50,50,50,0.5)",
                            borderRadius: "50%",
                            border: "none",
                            color: "white",
                            fontSize: "20px",
                            padding: "5px 2px 4px 5px",
                            zIndex: 100
                        }}
                        type={"button"}
                        onClick={async () => {
                            await deleteTemplate(signage._id)
                        }}
                    >
                        <Icon
                            size='tiny'
                            style={{
                                opacity: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            name='trash'
                        />
                    </button>
                    <button
                        style={{
                            background: "rgb(50,50,50,0.5)",
                            borderRadius: "50%",
                            border: "none",
                            color: "white",
                            fontSize: "20px",
                            padding: "5px 2px 4px 5px",
                            zIndex: 100
                        }}
                        type={"button"}
                        onClick={() => populateForm(signage)}
                    >
                        <Icon
                            size='tiny'
                            style={{
                                opacity: 0.5,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            name='pencil'
                        />
                    </button>
                </div>
                <p className="legend" onClick={() => populateForm(signage)}>
                    <span className="subject">{signage.Subject}</span>
                    <br />
                    <span className="dates">Start Date: {formatISODate(signage.StartDate)}</span>
                    <br />
                    <span className="dates">End Date: {formatISODate(signage.EndDate)}</span>
                    <br />
                    <Popup
                        trigger={<div className="rooms">Rooms: {shortRoomNames}</div>}
                        content={
                            <div style={{
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                overflowWrap: 'break-word',
                                maxWidth: '400px',
                                padding: '5px',
                            }}>
                                {`Rooms: ${popupRoomNames}`}
                            </div>
                        }
                        position="bottom center"
                        style={{
                            backgroundColor: "white",
                            color: "black",
                            fontSize: "14px",
                            width: "400px",
                            maxWidth: "400px",
                        }}
                        inverted
                    />
                </p>
            </div>
        )
    };
    const carouselItems = signages.map((signage) => createCarouselItemImage(signage));

    const alexaPreview = () => {
        // Trial and error based formulas
        const bottomleftFontSize = (previewImgSize.Height * 2) / 21;
        const topleftFontSize = bottomleftFontSize * 1.5;
        const bottomleftsubFontSize = bottomleftFontSize / 2.5;
        const topleftPosition = (bottomleftFontSize * 1.5 / 3) + (bottomleftFontSize * 1.5 / 3 * 300 / 570);
        const bottomleftPosition = (bottomleftFontSize) + (bottomleftFontSize * 300 / 570);
        const bottomleftsubPosition = (bottomleftFontSize / 2.5) + (bottomleftFontSize / 2.5 * 1 / 570);
        return (
            <div className={styles.container}>
                <div>
                    <img style={{
                        width: "800px",
                        height: "450px",
                        objectFit: "cover",
                        maxWidth: "100%"
                    }} onLoad={() => setPreviewImgSize({ Height: previewImgRef.current.clientHeight, Width: previewImgRef.current.clientWidth })} className={styles.image} ref={previewImgRef} src={previewUrl} />
                </div>

                <div className={styles.topleft} style={{ fontSize: `${topleftFontSize}px`, top: `${topleftPosition}px` }}>{previewTime}</div>
                <div className={styles.bottomleft} style={{ fontSize: `${bottomleftFontSize}px`, bottom: `${bottomleftPosition}px` }}>{ }{formValues.title ? formValues.title : "Title"}</div>
                <div className={styles.bottomleftsub} style={{ fontSize: `${bottomleftsubFontSize}px`, bottom: `${bottomleftsubPosition}px` }}>{formValues.subTitle ? formValues.subTitle : "Subtitle"}</div>
            </div>
        );
    };

    const formatAndSortRooms = (rooms) => {
        const formattedRooms = rooms.map((obj) => {
            return {
                key: `${obj.RoomId}`,
                text: `${obj.Resident}`,
                value: `${obj.RoomId}`
            };
        });

        const sortedRooms = formattedRooms.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedRooms;
    }

    const formatAndSortRoomGroups = (groups) => {
        const formattedRoomGroups = groups.map((obj) => {
            return {
                key: `${obj._id}`,
                text: `${obj.Name} group`,
                value: `${[obj.RoomIds]}`,
            };
        });

        const sortedRoomGroups = formattedRoomGroups.sort((a, b) => {
            const A = a.text.toUpperCase();
            const B = b.text.toUpperCase();

            if (A < B) {
                return -1;
            }
            if (A > B) {
                return 1;
            }
            return 0;
        });

        return sortedRoomGroups;
    }

    const fetchSignage = async () => {
        try {

            let filter: SignageFilter = {};

            if (dateRange.startDate && dateRange.endDate) {
                filter.StartDate = moment(dateRange.startDate).format("YYYY/MM/DD");
                filter.EndDate = moment(dateRange.endDate).format("YYYY/MM/DD");
            }

            if (searchQuery) {
                filter.SearchQuery = searchQuery;
            }

            if (selectedGroup !== "all") {
                const selectedGroupObj = groups.find(group => group._id === selectedGroup);
                if (selectedGroupObj && selectedGroupObj.RoomIds) {
                    filter.RoomIds = selectedGroupObj.RoomIds;
                }
            }

            if (Object.keys(filter).length > 0) {
                return await fetchPaginatedActiveSignage(pageNum, pageSize, filter);
            } else {
                return await fetchPaginatedActiveSignage(pageNum, pageSize);
            }
        } catch (error) {
            console.error(error)
            if (error instanceof Error && error.message && typeof (error.message) === 'string')
                setErrorMsg(error.message)
        }
    }

    const populateForm = (signage: SignageType) => {
        setPreviewUrl(signage.ImageUrl)
        setFormValues({ title: signage.Subject, subTitle: signage.Message })
        setStartDateTimeString('');
        setEndDateTimeString('');
        setSignageDisplayLocation(["Alexa"])
        // The following is kept commented in case of reverting back to old logic
        // const currentTime = new Date().toISOString();
        // const isSignageTemplateInFuture = signage.EndDate > currentTime;
        // if (isSignageTemplateInFuture) {
        //     const isStartDateInFuture = signage.StartDate > currentTime;
        //     if (isStartDateInFuture) {
        //         setStartDate(signage.StartDate)
        //     } else {
        //         setStartDate(currentTime)
        //     }
        //     setEndDate(signage.EndDate)
        // } else {
        //     setStartDate(currentTime)
        //     setEndDate(tenMinutesFromNow(new Date(currentTime)))
        // }
        const roomValues = rooms.map((room) => room.value)

        const filtereddRooms = signage.RoomIds.filter((el) => {
            return roomValues.indexOf(el) > -1
        })

        setSelectedRooms(filtereddRooms)
        setTemplateUrl(signage.ImageUrl)
        setFileSizeExceeded('')
    }

    const formatFileName = (fileName) => {
        // Preserve file extension (.jpg, .png)
        const fileNameArray = fileName.split(".")
        const ext = fileNameArray.pop()
        const rawFileName = fileNameArray.join("")
        const URLSafeFileName = rawFileName.replace(/[^a-zA-Z0-9]/g, '') //strip url unsafe elements
        return (URLSafeFileName + '.' + ext) // file name + extension
    }

    const imageUploadHandler = async (image: File[]) => {
        try {
            if (image && image.length && profile && profile.Facility) {
                const { Facility } = profile;
                const currentFileName = formatFileName(image[0].name)
                setImageUploadStatus(prev => ({ ...prev, isUploading: true }))
                const url = await uploadSignageFile(currentFileName, image[0], Facility, (percentage) => setImageUploadStatus((prev) => ({ ...prev, percentage: percentage })))
                setImageUploadStatus(prev => ({ ...prev, isUploading: false, finished: true }))
                return url
            } else {
                throw new Error("Image file was not provided")
            }
        } catch (error) {
            setImageUploadStatus(prev => ({ ...prev, error: error instanceof Error ? error.message : "Image Upload Failed", finished: true }))
            setErrorMsg(`Error while uploading`)
            console.error("Error from imageUploadHandler", error)
        }
    }

    const handleDropdownChange = (roomIds) => {
        const roomsHandlingGroups = roomIds.map((roomId) => {
            if (roomId.indexOf(',') !== -1) {
                return roomId.split(',')
            }
            return roomId
        })
        const rooms = roomsHandlingGroups.flat()

        if (rooms.includes('all')) {
            setSelectedRooms(['all'])
        } else {
            setSelectedRooms(rooms)
        }
    }

    const handleGroupDropdownChange = (value) => {
        if (value === selectedGroup) {
            return
        } else if (value === "All") {
            setSelectedGroup('all')
        } else if (!value) {
            setSelectedGroup("all")
        } else {
            setSelectedGroup(value)
        }
    }

    const validInputData = () => {
        if (!formValues.title.length || !formValues.subTitle.length) {
            setErrorMsg("Missing fields Title/SubTitle");
            return false;
        } else if (!startDateTimeString) {
            setErrorMsg("Start date is required");
            return false;
        } else if (!endDateTimeString) {
            setErrorMsg("End date is required");
            return false;
        } else if (!templateUrl && !imageFile.length) {
            setErrorMsg("Image file is required");
            return false;
        } else if (!selectedRooms.length) {
            setErrorMsg("Select at least one room to create a signage/proactive suggestion");
            return false;
        } else {
            setErrorMsg("");
            return true;
        }
    }

    const clearForm = () => {
        setStartDateTimeString('');
        setEndDateTimeString('')
        setImageFile([])
        setSelectedRooms(['all'])
        setErrorMsg('')
        setTemplateUrl(defaultSignageImage);
        setPreviewUrl(defaultSignageImage);
        setFormValues({
            title: "",
            subTitle: ""
        })
        setImageUploadStatus({
            percentage: 0,
            isUploading: false,
            finished: false,
            error: '',
        })
    }

    const clearState = () => {
        setStartDateTimeString('')
        setEndDateTimeString('')
        setLoading(false)
        setSubmitting(false)
        setImageFile([])
        setSelectedRooms(['all'])
        setErrorMsg('')
        setOnSuccess(false)
        setImageUploadStatus({
            percentage: 0,
            isUploading: false,
            finished: false,
            error: '',
        })
        setFormValues({
            title: "",
            subTitle: ""
        })
        setTemplateUrl(defaultSignageImage);
        setPreviewUrl(defaultSignageImage);
        setPreviewTime("")
        setSignages([]);
        setPageNum(1);
        setRefreshSignages((prev) => !prev);
        setSignageDisplayLocation(["Alexa"])
    }

    const handleSubmit = async () => {
        setOnSuccess(false)
        // check if start date and end date are provided
        if (!startDateTimeString || !endDateTimeString || !facilityTimeZone) {
            setErrorMsg("Missing start or end date");
            return;
        }
        // check if start date is less than end date
        if (startDateTimeString >= endDateTimeString) {
            setErrorMsg("Start date should be less than end date");
            return;
        }
        const startISOString = moment.tz(startDateTimeString, 'YYYY-MM-DDTHH:mm:ss', facilityTimeZone).toISOString();
        const endISOString = moment.tz(endDateTimeString, 'YYYY-MM-DDTHH:mm:ss', facilityTimeZone).toISOString();
        try {
            let allRooms = false;
            setSubmitting(true);
            setSignages([]); //to avoid duplicates
            if (!validInputData()) return
            let imageUrl: string | undefined = ""
            if (!templateUrl) {
                imageUrl = await imageUploadHandler(imageFile)
            } else {
                imageUrl = templateUrl
            }
            let roomIds = [...selectedRooms]

            // handle all rooms option
            if (roomIds[0] === 'all') {
                allRooms = true
                const roomsWithoutAll = rooms.slice(1)
                roomIds = roomsWithoutAll.map((room) => {
                    return room.value
                })
                roomIds = roomIds.filter((room) => {
                    if (room.indexOf(',') === -1) return room
                })
            }
            setTotalRooms(roomIds.length)
            setNotifyingRoom(roomIds.length)           
            const insertionResponse = await addSignage(
                formValues.title,
                formValues.subTitle,
                roomIds,
                imageUrl,
                startISOString,
                endISOString,
                allRooms,
                signageDisplayLocation
            )
            clearState()
            setOnSuccess(true)
            setSubmitting(false)
            toast.success("Signage creation is in progress. It will take a few minutes to reflect on this page. Please revisit shortly.", {
                position: 'bottom-center',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });            
        } catch (error) {
            console.log('Error ::', error);
            if (error.message && /unsupported mime type/gi.test(error.message)) {
                toast.warn("Please upload images of jpg or png format", {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            toast.error("Something went wrong, please try again", {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            clearState();
            setOnSuccess(false);
            setSubmitting(false);
        }
    }

    const handleChange = async (item, isChecked, displayLocation) => {
        setLoading(true);
        if (profile) await updateSignageContent(item, isChecked, signageContentObj && signageContentObj._id || "", profile.Facility, displayLocation);
        const res = await fetchSignageContentObj();
        if (res) {
            setContentDisplayLocation(res.displayLocation || ["Alexa"]);
            setSignageContentObj(res);
        } else {
            sendToast("error", "Failed to fetch Signage content");
        }
        setLoading(false);
    }
    const imageClickHandler = (signageImageUrl: string) => {
        setActiveTabIndex(0)
        setPreviewUrl(signageImageUrl)
        setTemplateUrl(signageImageUrl)
    }

    const formatString = (str: string) => {
        const specialCases = {
            "sotm": "Staff Spotlight",
            "rotm": "Resident Spotlight"
        }

        const specialCaseResult = specialCases[str.toLowerCase()];
        if (specialCaseResult) {
            return specialCaseResult
        } else {
            // /([A-Z])/g matches all uppercase letters in the string. The $1 adds a space before each matched letter. The /^./ regular expression matches the first character of the string and converts it to uppercase
            return `${str.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}`
        }
    }

    const contentTypePanes = contentTypes.map((el) => {
        return {
            menuItem: formatString(el),
            render: () => (
                <Tab.Pane attached={false}>
                    <PhotoGrid imageUrls={galleryImages} onImageClick={imageClickHandler} refreshGalleryImage={refreshGalleryImage} activeContentType={activeContentTypeTab} />
                    <LoadMore hasMore={!!nextContinuationToken} isLoading={loading} next={async () => {
                        setLoading(true)
                        setNextContinuationToken(null) // clear to make it correctly work between diffrent content types
                        if (activeContentTypeTab) {
                            const res = await listSignageImagesForContentTypeFromS3(activeContentTypeTab, imagePerPage, nextContinuationToken, imageETags)
                            setGalleryImages(prev => [...prev, ...res.images])
                            setNextContinuationToken(res.nextContinuationToken)
                            setImageEtags((prev) => [...prev, , ...res.etags])
                            setLoading(false)
                        }
                    }} />
                </Tab.Pane>
            )
        }
    })

    const defaultImageTypepanes = defaultImageTypes.map((el) => {
        return {
            menuItem: el.charAt(0).toUpperCase() + el.slice(1),
            render: () => (
                <Tab.Pane attached={false}>
                    <PhotoGrid imageUrls={galleryImages} onImageClick={imageClickHandler} />
                    <LoadMore hasMore={!!nextContinuationToken} isLoading={loading} next={async () => {
                        setLoading(true);
                        setNextContinuationToken(null); // clear to make it correctly work between diffrent content types
                        if (activeContentTypeTab) {
                            const res = await listDefaultSignageImages(activeContentTypeTab, imagePerPage, nextContinuationToken, imageETags);
                            setGalleryImages(prev => [...prev, ...res.images]);
                            setNextContinuationToken(res.nextContinuationToken);
                            setImageEtags((prev) => [...prev, , ...res.etags]);
                            setLoading(false);
                        }
                    }} />
                </Tab.Pane>
            )
        };
    })

    const UploadImagePanes = [
        {
            menuItem: 'Upload',
            render: () => (
                <Tab.Pane attached={false}>
                    <FilePicker
                        accept={['image/*']}
                        onSelected={(file) => {
                            if (file[0].type !== "image/jpeg" && file[0].type !== "image/png" && file[0].type !== "image/jpg"){
                                toast.warn("Please upload images of jpg or png format", {
                                    position: 'bottom-center',
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                })
                                return
                            }
                            // reset fields
                            setImageFile([]);
                            setTemplateUrl('');
                            setPreviewUrl(
                                defaultSignageImage,
                            );
                            setImageUploadStatus({
                                percentage: 0,
                                isUploading: false,
                                finished: false,
                                error: '',
                            });

                            // validate
                            // if (file && file[0] && file[0].size && file[0].size > 100000) {
                            //     setFileSizeExceeded('File size should not exceed 100 KB');
                            //     return;
                            // } else {
                            //     setFileSizeExceeded('');
                            // }

                            // update
                            setImageFile(file);
                            setImageUploadStatus((prev) => ({ ...prev, isUploading: false, finished: true }));
                        }}
                        {...imageUploadStatus}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'My Images',
            render: () => (
                <Tab
                    menu={{ secondary: true, pointing: true, className: "wrapped" }}
                    panes={contentTypePanes}
                    activeIndex={myImagesActiveTab}
                    onTabChange={(e, data) => {
                        const specialCases = {
                            "Staff Spotlight": "SOTM",
                            "Resident Spotlight": "ROTM"
                        }
                        const activeIndex = data.activeIndex
                        if (activeIndex !== undefined && activeTabIndex !== null) {
                            setMyImagesActiveTab(data.activeIndex)
                            if (data.activeIndex !== undefined && data.panes && data.panes.length && data.panes[data.activeIndex]) {
                                const tabContentType = data.panes[data.activeIndex].menuItem;
                                const specialCaseResult = specialCases[tabContentType]
                                if(specialCaseResult) {
                                    setActiveContentTypeTab(specialCaseResult)
                                } else {
                                    const correctFormatContentType = contentTypes.filter((el) => {
                                        return el.toLowerCase() === tabContentType.replace(/\s/g, '').toLowerCase(); // /\s/g -> symbol for space
                                    })[0];
                                    setActiveContentTypeTab(correctFormatContentType);
                                }
                              
                            }

                        }
                    }}
                >
                </Tab>
            ),
        },
        ...defaultImageTypepanes
        // {
        //     menuItem: 'Default Images',
        //     render: () => (
        //         <Tab
        //             menu={{ secondary: true, pointing: true, className: "wrapped" }}
        //             panes={defaultImageTypepanes}
        //             activeIndex={activeDefaultImageTabIndex}
        //             onTabChange={(e, data) => {
        //                 setActiveDefaultImageTabIndex(data.activeIndex);
        //                 if (data.activeIndex !== undefined && data.panes && data.panes.length && data.panes[data.activeIndex]) {
        //                     const tabContentType = data.panes[data.activeIndex].menuItem;
        //                     const correctFormatContentType = defaultImageTypes.filter((el) => {
        //                         return el.toLowerCase() === tabContentType.replace(/\s/g, '').toLowerCase(); // /\s/g -> symbol for space
        //                     })[0];
        //                     setActiveDefaultImageTab(correctFormatContentType);
        //                 }
        //             }}
        //         />
        //     ),
        // },
        // ...contentTypePanes,
    ]

    const panes = [
        {
            menuItem: 'Signage',
            render: () => (
                <Tab.Pane attached={false}>
                    <Segment raised className="mx-6">
                        <Dimmer inverted active={loading}>
                            <Loader>Loading...</Loader>
                        </Dimmer>
                        <Dimmer inverted active={submitting}>
                            {totalRooms ? <Loader>{`Creating signage for ${notifyingRoom} of ${totalRooms}`}</Loader> : <Loader>{`Creating signage`}</Loader>}
                        </Dimmer>
                        <div style={{ position: "relative" }} >
                            <Dimmer active={isFetchingTemplates && !submitting && !loading} page={false} inverted>
                                <Loader active={isFetchingTemplates && !submitting && !loading}></Loader>
                            </Dimmer>
                            <div style={{ display: "flex", gap: '5px', alignItems: "center", height: '50px', paddingBottom: '15px', marginBottom: "20px" }}>
                                <Header style={{ marginTop: '48px' }} size='tiny'>Preview Slides</Header>
                                <Icon name='calendar alternate outline' style={{ marginTop: '37px' }} size='large' onClick={() => {
                                    setShowDateRange((prev) => !prev);
                                }} />
                                {
                                    // add two new filters here one for title and subtitle and one for groups
                                    showDateRange &&
                                    <Form
                                        onSubmit={async () => {
                                            if (searchQuery.length > 0) {
                                                setSignages([]); // clear previous data to avoid duplicates
                                                setPageNum(1);// clear page number 
                                                await refreshData();
                                            }
                                        }}
                                        style={{ display: 'flex', gap: '10px', }}
                                    >
                                        <Label style={{ background: "white" }}>
                                            Start Date:&nbsp;
                                            <DatePicker
                                                selected={dateRange.startDate || ""}
                                                onChange={async (date) => {
                                                    setDateRange((prev) => {
                                                        return { ...prev, startDate: date };
                                                    });
                                                }}
                                                startDate={dateRange.startDate}
                                                customInput={<Input />}
                                                maxDate={dateRange.endDate}
                                                selectsStart
                                                placeholder="Select start date"
                                                style={{ marginLeft: "3px" }}
                                                value={dateRange.startDate || ""}
                                            />
                                        </Label>
                                        <Label style={{ background: "white" }}>
                                            End Date:&nbsp;
                                            <DatePicker
                                                selected={dateRange.endDate || ""}
                                                onChange={async (date) => {
                                                    setDateRange((prev) => ({ ...prev, endDate: date }));
                                                }}
                                                endDate={dateRange.endDate}
                                                customInput={<Input />}
                                                selectsEnd
                                                placeholder="Select end date"
                                                value={dateRange.endDate || ""}
                                            />
                                        </Label>
                                        <Label style={{ background: "white", display: 'flex', flexDirection: "column" }}>
                                            <div>Search by title or subtitle:&nbsp;</div>
                                            <Input action={{ icon: 'search' }}
                                                placeholder=' Search by title or subtitle...'
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </Label>
                                        <Label style={{ background: "white", display: 'flex', flexDirection: "column", marginLeft: "65px" }}>
                                            <div>Search by user/group:&nbsp;</div>
                                            <Dropdown
                                                closeOnEscape
                                                value={selectedGroup}
                                                search
                                                scrolling
                                                selection
                                                style={{ fontWeight: "normal" }}
                                                options={[
                                                    { value: 'all', text: 'All' },
                                                    ...sortByKey(groups.map((item) => {
                                                        return { value: item._id, text: item.Name };
                                                    })),
                                                ]}
                                                onChange={(e, d) => handleGroupDropdownChange(d.value)}
                                            />
                                        </Label>
                                    </Form>
                                }
                            </div>
                            {signages && signages.length ? (
                                <div>
                                    <div style={{ width: "100%" }}>
                                        <Carousel
                                            statusFormatter={(current, total) => `${total - current + 1} of ${total}`}
                                            className="custom-carousel"
                                            centerMode
                                            centerSlidePercentage={25}
                                            showArrows={true}
                                            showThumbs={false}
                                            useKeyboardArrows={true}
                                            swipeable={true}
                                            emulateTouch={true}
                                            thumbWidth={100}
                                            ariaLabel={'ariaLabel'}
                                            showIndicators={false}
                                            dynamicHeight={false}
                                            selectedItem={selectedSlide}
                                            onChange={async (index) => {
                                                if (index === 1) {
                                                    setSelectedSlide(1);
                                                }
                                                if (index === 0 && hasMoreTemplates) {
                                                    setPageNum((prev) => prev + 1);
                                                } else if (!hasMoreTemplates && (index === 0)) {
                                                    toast.info("There are no more signage templates available", {
                                                        position: 'bottom-center',
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                    });
                                                }
                                            }}
                                        >
                                            {carouselItems.reverse()}
                                        </Carousel>
                                    </div>

                                </div>

                            ) : (<Message>There are no signage previews</Message>)} {/* confirm this string */}
                        </div>
                        <Grid columns={2} stackable>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <div>
                                        {/* If image size exceeds 100kb, an error message is displayed */}
                                        {fileSizeExceeded ? <Message negative>{fileSizeExceeded}</Message> : <></>}
                                        <div style={{ margin: '10' }}>{previewUrl ? alexaPreview() : <></>}
                                            {previewUrl === defaultSvcImage && <Message>If this is not the correct signage image, please edit and re-upload the correct image.</Message>}
                                        </div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <div>
                                        <Header style={{ marginTop: '15px' }} size='tiny'>Create Signage</Header> {/* check this string */}
                                        <Dimmer inverted active={imagesLoading && !loading}>
                                            <Loader>Loading...</Loader>
                                        </Dimmer>
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Input
                                                error={errorTitle}
                                                fluid
                                                value={formValues.title}
                                                onChange={(e) => setFormValues({ title: e.target.value, subTitle: formValues.subTitle })}
                                                placeholder="Title"
                                            />
                                            <Form.Input
                                                error={errorSubTitle}
                                                fluid
                                                value={formValues.subTitle}
                                                onChange={(e) => setFormValues({ title: formValues.title, subTitle: e.target.value })}
                                                placeholder="Subtitle"
                                            />
                                            <Form.Input>

                                                <DatePicker
                                                    selected={(Date.parse(startDateTimeString)) || ""}
                                                    onChange={(date: Date) => {
                                                        const minutesWithPadedZero =
                                                            String(date.getMinutes()).length === 1
                                                                ? '0' + String(date.getMinutes())
                                                                : date.getMinutes();
                                                        // preview time for alexa faux preview
                                                        setPreviewTime(`${date.getHours()}:${minutesWithPadedZero}`);
                                                        setStartDateTimeString(moment(date).format('YYYY-MM-DDTHH:mm:ss'));
                                                    }}
                                                    onCalendarOpen={() => {
                                                        if (!startDateTimeString) {
                                                            const currentTime = moment();
                                                            const isBefore9am = currentTime.hour() < 9;
                                                            const defaultTime = isBefore9am ? currentTime.hour(9).minute(0).second(0) : currentTime;
                                                            const date = defaultTime.toDate();
                                                            const minutesWithPaddedZero = String(date.getMinutes()).length === 1
                                                                ? '0' + String(date.getMinutes())
                                                                : date.getMinutes();
                                                            setPreviewTime(`${date.getHours()}:${minutesWithPaddedZero}`);
                                                            setStartDateTimeString(moment(date).format('YYYY-MM-DDTHH:mm:ss'));
                                                        }
                                                    }}
                                                    dateFormat="M.d.Y h:mm a"
                                                    showTimeSelect
                                                    timeIntervals={15}
                                                    startDate={Date.parse(startDateTimeString)}
                                                    endDate={Date.parse(endDateTimeString)}
                                                    minDate={new Date()}
                                                    maxDate={Date.parse(endDateTimeString)}
                                                    minTime={
                                                        (startDateTimeString === moment().format('YYYY-MM-DDTHH:mm:ss'))
                                                            ? Date.now()
                                                            : false
                                                    }
                                                    maxTime={
                                                        (startDateTimeString === moment().format('YYYY-MM-DDTHH:mm:ss'))
                                                            ? new Date().setHours(23, 59, 0, 0)
                                                            : false
                                                    }
                                                    selectsStart
                                                    placeholderText="Select start date"
                                                />
                                            </Form.Input>
                                            <Form.Input>
                                                <DatePicker
                                                    selected={Date.parse(endDateTimeString) || ""}
                                                    onChange={(date: Date) => {
                                                        setEndDateTimeString(moment(date).format('YYYY-MM-DDTHH:mm:ss'));
                                                    }}
                                                    dateFormat="M.d.Y h:mm a"
                                                    showTimeSelect
                                                    timeIntervals={15}
                                                    startDate={Date.parse(startDateTimeString)}
                                                    endDate={Date.parse(endDateTimeString)}
                                                    minDate={new Date(tenMinutesFromNow(startDateTimeString ? new Date(startDateTimeString) : new Date()))}
                                                    minTime={
                                                        (startDateTimeString && (startDateTimeString === endDateTimeString))
                                                            ? new Date().setTime(new Date(startDateTimeString).getTime() + 1000 * 60 * 10)
                                                            : new Date().setHours(0, 0, 0, 0)
                                                    }
                                                    maxTime={new Date().setHours(23, 59, 59, 0)}
                                                    selectsEnd
                                                    placeholderText="Select end date"
                                                />
                                            </Form.Input>
                                            <Form.Field>
                                                <Dropdown
                                                    closeOnEscape
                                                    value={selectedRooms}
                                                    multiple
                                                    clearable
                                                    search
                                                    scrolling
                                                    selection
                                                    options={sortByKey(rooms)}
                                                    onChange={(e, d) => handleDropdownChange(d.value)}
                                                />
                                                <Form.Field style={{ marginTop: "10px" }}>
                                                    <List>
                                                        <List.Item style={{ display: 'flex', gap: "15px" }}>
                                                            <h5 style={{ marginTop: "3px" }}>Display Locations:</h5>
                                                            <label style={{ marginTop: "3px" }}>Alexa</label>
                                                            <Checkbox
                                                                label="Alexa and TV"
                                                                toggle
                                                                checked={signageDisplayLocation.includes("TV")}
                                                                onChange={(e, data) => {
                                                                    setSignageDisplayLocation(data.checked ? ["TV", "Alexa"] : ["Alexa"]);
                                                                }}
                                                            />
                                                        </List.Item>
                                                    </List>
                                                </Form.Field>
                                                {/* todo the below code can be used for  */}
                                                {/* <Form.Input readOnly={true} disabled className='distance-input'>
                                <input
                                    readOnly={true}
                                    value={distanceValue || distanceHours ? `${distanceValue} ${distanceValue > 1 ? "days" : "day"} ${distanceHours > 1 ? "hour" : "hours"} ahead` : ""}
                                    placeholder="Distance will be displayed here once you selecte the dates"
                                />
                            </Form.Input> */}
                                            </Form.Field>
                                            <Form.Field>
                                                <Button disabled={disableSave} type="submit" primary>
                                                    Save
                                                </Button>
                                                <Button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        clearForm();
                                                    }}
                                                    basic
                                                >
                                                    Cancel
                                                </Button>
                                                <Icon name="plus" onClick={() => clearForm()} style={{ cursor: "pointer", marginLeft: "5px" }} />
                                            </Form.Field>

                                        </Form>
                                        {errorMsg ? <Message negative>{errorMsg}</Message> : <></>}
                                        {onSuccess ? <Message positive> Sucessfully created signage</Message> : <></>}
                                        <Tab
                                            menu={{ secondary: true, pointing: true, className: "wrapped" }}
                                            panes={UploadImagePanes}
                                            activeIndex={activeGalleryTabIndex}
                                            onTabChange={(e, data) => {
                                                setMyImagesActiveTab(undefined);
                                                setActiveGalleryTabIndex(data.activeIndex);
                                                if (data.activeIndex !== undefined && data.panes && data.panes.length && data.panes[data.activeIndex]) {
                                                    const tabContentType = data.panes[data.activeIndex].menuItem;
                                                    const correctFormatContentType = defaultImageTypes.filter((el) => {
                                                        return el.toLowerCase() === tabContentType.replace(/\s/g, '').toLowerCase(); // /\s/g -> symbol for space
                                                    })[0];
                                                    setActiveContentTypeTab(correctFormatContentType);
                                                    const isMyImagesTabActive = data.panes[data.activeIndex].menuItem === "My Images";
                                                    if (isMyImagesTabActive) { // to handle the initial My Images fetch
                                                        setMyImagesActiveTab(0);
                                                        const firstContentType = contentTypes[0];
                                                        if (firstContentType) {
                                                            setActiveContentTypeTab(firstContentType);
                                                        }
                                                    }
                                                }
                                            }}
                                            style={{ marginTop: "1em" }}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Content',
            render: () => (
                <Tab.Pane attached={false}>
                    <Content
                        view='list'
                        Menu={signageContentObj && signageContentObj.menu || false}
                        Activities={signageContentObj && signageContentObj.activities || false}
                        Birthdays={signageContentObj && signageContentObj.birthdays || false}
                        Rotm={signageContentObj && signageContentObj.rotm || false}
                        Sotm={signageContentObj && signageContentObj.sotm || false}
                        CommunityMessages={signageContentObj && signageContentObj.communityMessages || false}
                        displayLocation={contentDisplayLocation}
                        setDisplayLocation={setContentDisplayLocation}
                        handleChange={handleChange}
                        isLoading={loading}
                    />
                </Tab.Pane>
            ),
        }
    ]

    return (
        <>
            <Tab
                menu={{ secondary: true, pointing: true }}
                panes={panes}
                activeIndex={activeTabIndex}
                onTabChange={(e, data) => {
                    setActiveTabIndex(data.activeIndex)
                }}
            />
        </>
    )
}

export default Signage